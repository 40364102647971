const INSTITUTIONS_ADMIN_CREATION_UI = [
  {
    label: 'RUT', key: 'rut', valueType: 'string', typeField: 'text',
  },
  {
    label: 'Nombre', key: 'name', valueType: 'string', typeField: 'text',
  },
  {
    label: 'Apellido', key: 'lastname', valueType: 'string', typeField: 'text',
  },
  {
    label: 'Email', key: 'email', valueType: 'string', typeField: 'text',
  },
  {
    label: 'Contraseña', key: 'password', valueType: 'other', typeField: 'text',
  },
];

type InstitutionAdminData = {
  rut: string,
  name: string,
  lastname: string,
  email: string,
  password: string,
  [key: string]: string;
};

export {
  INSTITUTIONS_ADMIN_CREATION_UI,
  InstitutionAdminData,
};
