import React, { SyntheticEvent } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Box,
  Button,
  TextField,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

import handleApiResponse from '../../utils/handleApiResponse';
import { logout } from '../../actions/accountActions';
import { institutionLoginSuccess } from '../../actions/institutionAccountActions';
import { saveInstitutionUserInfo } from '../../requests/api/institutionUsers';

import {
  CARRERS, InstitutionUser, SCHOOLS, UNIVERSITIES,
} from './types';

function UserInformationForm({
  className,
  urlExtension,
  institutionUserId,
  initialValues,
  setInstitutionUser,
  ...rest
}: {
  className?: string,
  urlExtension: string,
  institutionUserId: number,
  initialValues: InstitutionUser,
  setInstitutionUser: (institutionUser: InstitutionUser) => void,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        id: institutionUserId,
        name: null,
        lastname: null,
        email: null,
        phoneNumber: null,
        school: null,
        schoolName: null,
        schoolYear: null,
        preferredUniversity: null,
        preferredUniversityName: null,
        preferredCarrer: null,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('El nombre es requerido').nullable(),
        lastname: Yup.string().required('El apellido es requerido').nullable(),
        email: Yup.string().required('El email es requerido').nullable(),
        phoneNumber: Yup.string().required('El teléfono es requerido').nullable(),
        school: Yup.string().required('El colegio es requerido').nullable(),
        schoolName: Yup.string().when('school', {
          is: 'Otro',
          then: Yup.string().required('El nombre del colegio es requerido').nullable(),
          otherwise: Yup.string().nullable(),
        }),
        schoolYear: Yup.string().required('El curso es requerido').nullable(),
        preferredUniversity: Yup.string().required('La universidad de preferencia es requerida').nullable(),
        preferredUniversityName: Yup.string().when('preferredUniversity', {
          is: 'Otro',
          then: Yup.string().required('El nombre de la universidad de preferencia es requerido').nullable(),
          otherwise: Yup.string().nullable(),
        }),
        preferredCarrer: Yup.string().required('La universidad de preferencia es requerida').nullable(),
      })}
      onSubmit={async (values, {
        setSubmitting,
      }) => {
        try {
          const institutionUser = {
            id: values.id,
            name: values.name,
            lastname: values.lastname,
            email: values.email,
            phoneNumber: values.phoneNumber,
            school: values.school === 'Otro' ? values.schoolName : values.school,
            schoolYear: values.schoolYear,
            preferredUniversity: values.preferredUniversity === 'Otro' ? values.preferredUniversityName : values.preferredUniversity,
            preferredCarrer: values.preferredCarrer,
          };
          const response = await saveInstitutionUserInfo(institutionUser);
          if (response.success) {
            dispatch(logout());
            dispatch(institutionLoginSuccess(response.institutionUser));
            history.push(`/institucion/${urlExtension}/ensayos`);
          }
        } catch (err) {
          const e = err as AxiosError;
          handleApiResponse(enqueueSnackbar, e, false);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form
          noValidate
          className={clsx(className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(touched.name && errors.name)}
            color="secondary"
            fullWidth
            autoFocus
            helperText={touched.name && errors.name}
            label="Nombre"
            margin="normal"
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.name}
            variant="outlined"
            size="small"
          />
          <TextField
            error={Boolean(touched.lastname && errors.lastname)}
            fullWidth
            color="secondary"
            helperText={touched.lastname && errors.lastname}
            label="Apellido"
            margin="normal"
            name="lastname"
            onBlur={handleBlur}
            onChange={handleChange}
            type="lastname"
            value={values.lastname}
            variant="outlined"
            size="small"
          />
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            color="secondary"
            helperText={touched.email && errors.email}
            label="Correo"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
            size="small"
          />
          <TextField
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            fullWidth
            color="secondary"
            helperText={touched.phoneNumber && errors.phoneNumber}
            label="Teléfono"
            margin="normal"
            name="phoneNumber"
            onBlur={handleBlur}
            onChange={handleChange}
            type="phoneNumber"
            value={values.phoneNumber}
            variant="outlined"
            size="small"
          />
          <Autocomplete
            size="small"
            options={SCHOOLS}
            fullWidth
            value={values.school ?? ''}
            onChange={(e: SyntheticEvent<Element, Event>, value) => setFieldValue('school', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Colegio"
                variant="outlined"
                color="secondary"
              />
            )}
            sx={values.school === 'Otro' ? { marginBottom: '5px !important' } : {}}
          />
          {values.school === 'Otro' ? (
            <TextField
              error={Boolean(touched.schoolName && errors.schoolName)}
              fullWidth
              color="secondary"
              helperText={touched.schoolName && errors.schoolName}
              label=""
              margin="normal"
              name="schoolName"
              onBlur={handleBlur}
              onChange={handleChange}
              type="schoolName"
              value={values.schoolName}
              variant="outlined"
              sx={{ marginTop: '3px !important' }}
              placeholder="Colegio"
              size="small"
            />
          ) : null}
          <Autocomplete
            size="small"
            options={['Egresado', '4to Medio', '3ro Medio', '2do Medio', '1ro Medio', 'Otro']}
            fullWidth
            value={values.schoolYear ?? ''}
            onChange={(e: SyntheticEvent<Element, Event>, value) => setFieldValue('schoolYear', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Curso"
                variant="outlined"
                color="secondary"
              />
            )}
          />
          <Autocomplete
            size="small"
            options={UNIVERSITIES}
            fullWidth
            value={values.preferredUniversity ?? ''}
            onChange={(e: SyntheticEvent<Element, Event>, value) => setFieldValue('preferredUniversity', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Universidad de preferencia"
                variant="outlined"
                color="secondary"
              />
            )}
            sx={values.preferredUniversity === 'Otro' ? { marginBottom: '5px !important' } : {}}
          />
          {values.preferredUniversity === 'Otro' ? (
            <TextField
              error={Boolean(touched.preferredUniversityName && errors.preferredUniversityName)}
              fullWidth
              color="secondary"
              helperText={touched.preferredUniversityName && errors.preferredUniversityName}
              label=""
              margin="normal"
              name="preferredUniversityName"
              onBlur={handleBlur}
              onChange={handleChange}
              type="preferredUniversityName"
              value={values.preferredUniversityName}
              variant="outlined"
              sx={{ marginTop: '3px !important' }}
              placeholder="Universidad de preferencia"
              size="small"
            />
          ) : null}
          <Autocomplete
            size="small"
            options={CARRERS}
            fullWidth
            value={values.preferredCarrer ?? ''}
            onChange={(e: SyntheticEvent<Element, Event>, value) => setFieldValue('preferredCarrer', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Carrera de preferencia"
                variant="outlined"
                color="secondary"
              />
            )}
          />
          <Box sx={{ display: 'flex', margin: '10px 0 20px' }}>
            <Button
              disabled={isSubmitting}
              fullWidth
              size="large"
              variant="contained"
              onClick={() => setInstitutionUser(initialValues)}
              sx={{ backgroundColor: '#2C82B3', marginRight: '5px', textTransform: 'none' }}
            >
              Atrás
            </Button>
            <Button
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ backgroundColor: '#E94745', marginLeft: '5px', textTransform: 'none' }}
            >
              Ingresar
            </Button>
          </Box>
        </form>
      )
      }
    </Formik >
  );
}

export default UserInformationForm;
