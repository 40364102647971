import React, {
  forwardRef,
  useEffect,
  useCallback,
  ForwardRefRenderFunction,
} from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Fab, Theme } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useSelector } from 'react-redux';
import track from '../../utils/analytics';
import { PageProps, ReduxState } from '../../types';
import { STUDENT } from '../../utils/user_types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  whatsappButton: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    zIndex: 1000,
    backgroundColor: '#34D366',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#34D366',
      opacity: 0.8,
    },
    '& svg': {
      width: '40px',
      height: '40px',
    },
  },
}));

const Page: ForwardRefRenderFunction<HTMLDivElement, PageProps> = (
  { title, children, ...rest },
  ref,
) => {
  const location = useLocation();
  const pageStyle = useStyles();

  const account = useSelector((state: ReduxState) => state.institutionAccount);

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: location.pathname,
    });
  }, [location]);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/+56985092812', '_blank');
  };

  return (
    <div className={pageStyle.root} ref={ref} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
      {account.institutionUser && account.institutionUser.role === STUDENT && (
        <Fab
          aria-label="whatsapp"
          className={pageStyle.whatsappButton}
          onClick={handleWhatsAppClick}
        >
          <WhatsAppIcon />
        </Fab>
      )}
    </div>
  );
};

export default forwardRef(Page);
