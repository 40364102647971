import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

import { Box, IconButton } from '@mui/material';
import {
  RemoveRedEye as RemoveRedEyeIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@mui/icons-material';

import handleApiResponse from '../../utils/handleApiResponse';
import { getInstitutionSessionUserTests } from '../../requests/api/institutionTests';

import { ReduxState } from '../../types';
import { INSTITUTION_SESSION_TESTS_UI } from './types';

import CircularProgressComponent from '../../components/Loading/CircularProgressComponent';
import Page from '../../components/Layout/Page';
import CustomTable from '../../components/General/CustomTable';
import EmptyTable from '../../components/General/EmptyTable';
import InstitutionUserDetailHeader from './InstitutionUserDetailHeader';

import useStyles from './styles';

function InstitutionUserProfile() {
  const { institutionUrlExtension } = useParams<{ institutionUrlExtension: string }>();
  const [showLoading, setShowLoading] = useState(true);
  const [tests, setTests] = useState<{
    items: { testName: string, score: string, status: string }[],
    totalItems: number,
  }>({
    items: [],
    totalItems: 0,
  });

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const account = useSelector((state: ReduxState) => state.institutionAccount);
  const classes = useStyles();

  const handleViewTest = (institutionSessionUserTestId: number) => {
    history.push(`/institucion/${institutionUrlExtension}/ensayos/${institutionSessionUserTestId}/resultado`);
  };

  const handleAnswerTest = (institutionSessionUserTestId: number) => {
    history.push(`/institucion/${institutionUrlExtension}/ensayos/${institutionSessionUserTestId}`);
  };

  useEffect(() => {
    const loadInstitutionSessionUserTests = async () => {
      if (account.institutionUser) {
        try {
          const { institutionSessionUserTests } = await getInstitutionSessionUserTests(
            institutionUrlExtension, account.institutionUser.id,
          );
          const updatedTests = institutionSessionUserTests.map((test: any) => {
            if (test.status === 'Completado' || test.active === false) {
              test.action = (
                <IconButton onClick={() => handleViewTest(test.institutionSessionUserTestId)}>
                  <RemoveRedEyeIcon className={classes.icons} />
                </IconButton>
              );
            }
            if (test.status === 'En proceso' && test.active === true) {
              test.action = (
                <IconButton onClick={() => handleAnswerTest(test.institutionSessionUserTestId)}>
                  <ArrowForwardIosIcon className={classes.icons} />
                </IconButton>
              );
            }

            return test;
          });

          setTests({
            items: updatedTests,
            totalItems: institutionSessionUserTests.length,
          });
        } catch (err) {
          const e = err as AxiosError;
          handleApiResponse(enqueueSnackbar, e, false);
        }
      }
      setShowLoading(false);
    };
    loadInstitutionSessionUserTests();
  }, [institutionUrlExtension]);

  return (
    <Page title="Alumnos">
      {showLoading
        ? <CircularProgressComponent />
        : <>
          {account.institutionUser && (
            <Box mt={3}>
              <InstitutionUserDetailHeader student={account.institutionUser} />
              {tests.totalItems !== 0
                && <CustomTable
                  headers={INSTITUTION_SESSION_TESTS_UI}
                  data={{ values: tests.items, count: tests.totalItems }}
                />}
              {tests.totalItems === 0 && <EmptyTable />}
            </Box>
          )}
        </>}
    </Page>
  );
}

export default InstitutionUserProfile;
