import React, { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import {
  Typography,
  Grid,
  SvgIcon,
  Theme,
  TextField,
  InputAdornment,
  Button,
  Box,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { makeStyles } from '@mui/styles';
import { Search as SearchIcon } from '@mui/icons-material';
import CustomButton from '../General/CustomButton';
import { CustomAutocompleteTextField, CustomSelectTextField } from '../General/CustomTextfields';
import { ReduxState } from '../../types';

type SelectorProps = {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement> | Date, source: string) => void;
  options: { id: string; name: string }[];
  keyField: string,
};

type AutocompleteProps = {
  name: string;
  keyField: string;
  value: string;
  onChange: ((e: React.ChangeEvent<HTMLInputElement> | Date, source: string) => void) & ((
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string, value: string | object | null,
  ) => void);
  options: { id: string; name: string }[];
  valueToRetrieveFromSelect?: string;
  size?: 'medium' | 'small' | undefined
};

type HeaderProps = {
  icon?: JSX.Element;
  title: string;
  buttons?: ButtonProps[];
  search?: SearchProps;
  backUrl?: string;
  selectors?: SelectorProps[];
  autocompletes?: AutocompleteProps[];
  goBack?: boolean,
};

type ButtonProps = {
  icon?: JSX.Element;
  text: string;
  onClick: VoidFunction;
  disabled?: boolean;
};

type SearchProps = {
  text: string;
  value: string;
  onChangeSearchValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    display: 'flex',
    color: theme.palette.background.main,
  },
  backButton: {
    color: theme.palette.primary.red,
    width: 24,
    minWidth: 24,
  },
  // style for dropdown to match height and width of search
  selectStyle: {
    width: 200,
    marginRight: 8,
    '& .MuiInputBase-root': {
      height: '40px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiInputLabel-outlined': {
      lineHeight: '12px',
      bottom: 0,
    },
  },
  autocompleteStyle: {
    width: 200,
    marginRight: 8,
    '& .MuiInputBase-root': {
      height: '40px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiInputLabel-outlined': {
      lineHeight: '12px',
      bottom: 0,
    },
  },
  textfieldContainer: {
    minWidth: '250px',
    marginRight: '10px',
  },
}));

function Header({
  icon, title, buttons, search, backUrl, selectors, autocompletes, goBack,
}: HeaderProps) {
  const classes = useStyles();
  const history = useHistory();

  const {
    account,
  } = useSelector((storeState: ReduxState) => storeState);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems='center'
      spacing={3}
    >
      <Grid item className={classes.titleContainer}>
        {Boolean(account.user?.id) && backUrl && <Button
          className={classes.backButton}
          onClick={() => history.push(backUrl)}
        >
          <ArrowBackIosIcon />
        </Button>}
        {goBack && <Button
          className={classes.backButton}
          onClick={() => history.goBack()}
        >
          <ArrowBackIosIcon />
        </Button>}
        {icon && <SvgIcon >
          {icon}
        </SvgIcon>}
        <Typography
          ml={1}
          variant="h3"
          fontWeight='bold'
          display='flex'
          alignItems='center'
        >
          {title}
        </Typography>
      </Grid>
      <Grid item display="flex" alignItems="center" flexWrap="wrap">
        {buttons && buttons.map((button) => <CustomButton
          key={button.text}
          icon={button.icon}
          text={button.text}
          colorType="tertiary"
          onClick={button.onClick}
          disabled={button.disabled}
        />)}
        {selectors && selectors.map((selector) => (
          <CustomSelectTextField
            key={selector.name}
            keyField={selector.keyField}
            name={selector.name}
            value={selector.value}
            onChangeFunction={selector.onChange}
            selectFields={selector.options}
            valueToRetrieveFromSelect='id'
            className={classes.selectStyle}
          />
        ))}
        {autocompletes && autocompletes.map((elem) => (
          <Box key={elem.name} className={classes.textfieldContainer}>
            <CustomAutocompleteTextField
              keyField={elem.keyField}
              name={elem.name}
              value={elem.value}
              onChangeFunction={elem.onChange}
              selectFields={elem.options}
              valueToRetrieveFromSelect='id'
              size={elem.size}
            />
          </Box>
        ))}
        {search && <TextField
          size='small'
          InputProps={{ startAdornment: (<InputAdornment position='start'><SearchIcon color='primary' /></InputAdornment>) }}
          variant='outlined'
          placeholder={search.text}
          value={search.value}
          onChange={search.onChangeSearchValue}
        />}
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
