import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Add as AddIcon,
  Dashboard as DashboardIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Person as PersonIcon,
  Reply as ReplyIcon,
} from '@mui/icons-material';
import {
  Box, ClassNameMap, IconButton, Switch,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation, useParams } from 'react-router';
import { History } from 'history';
import Header from '../../../components/Layout/Header';
import Page from '../../../components/Layout/Page';
import EmptyTable from '../../../components/General/EmptyTable';
import CustomTable from '../../../components/General/CustomTable';
import CircularProgressComponent from '../../../components/Loading/CircularProgressComponent';
import UpsertModal from '../../../components/General/UpsertModal';
import handleApiResponse from '../../../utils/handleApiResponse';
import useStyles from '../styles';
import {
  getInstitutionSessionsPerPage,
  postInstitutionSession,
  putInstitutionSession,
  putActiveInstitutionSessions,
  deleteInstitutionSession,
  getInstitutionInfoByUrlExtension,
} from '../../../requests/api/institutions';
import CustomSessionComponent from './Component/CustomSessionComponent';
import {
  INSTITUTIONS_ADMIN_SESSIONS_UI,
  INSTITUTIONS_SESSIONS_MODAL_UI,
  INSTITUTIONS_SESSIONS_UI,
  InstitutionSession,
  InstitutionSessionTest,
  InstitutionSessionUser,
} from './types';
import DeleteModal from '../../../components/General/DeleteModal';
import UpsertConfirmationModal from './Component/UpsertConfirmationModal';
import { ReduxState } from '../../../types';
import { InstitutionAdminData } from '../InstitutionsListView/types';

const switchActions = (
  isActive: boolean,
  institutionSessionId: number,
  activeSwitchId: number | undefined,
  onSwitchChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    institutionSessionId: number,
    activeSwitchId: number,
  ) => Promise<void>,
  switchDisabled: boolean,
) => (<Box key={institutionSessionId} display='flex' alignItems='center' justifyContent='center'>
  <Switch
    checked={isActive}
    onChange={(e) => onSwitchChange(e, institutionSessionId, activeSwitchId || 0)}
    disabled={switchDisabled}
  />
</Box>);

const oneButtonAction = (
  buttonToDisplay: JSX.Element,
  history: History,
  redirectUrl: string,
  name: string,
  buttonEnabled?: boolean,
) => (<Box display='flex' alignItems='center' justifyContent='center'>
  <IconButton
    onClick={() => history.push({ pathname: redirectUrl, state: { name } })}
    disabled={!buttonEnabled}
  >
    {buttonToDisplay}
  </IconButton>
</Box>);

type Data = InstitutionSession & {
  institutionSessionTests: InstitutionSessionTest[]
  institutionSessionId: string,
  institutionSessionUsers: InstitutionSessionUser[]
};

const twoButtonsAction = (
  classes: ClassNameMap<string>,
  institutionSessionId: string,
  data: Data,
  handleUpsertModal: (data: Data) => void,
  setOpenDeleteModal: ({ open, id }: { open: boolean, id: string }) => void,
) => (<Box display='flex' alignItems='center' justifyContent='center'>
  <IconButton onClick={() => handleUpsertModal(data)} >
    <EditIcon className={classes.icons} />
  </IconButton>
  <IconButton onClick={() => setOpenDeleteModal({ open: true, id: institutionSessionId })}>
    <DeleteIcon className={classes.icons} />
  </IconButton>
</Box>);

interface LocationState {
  institutionName: string,
  institutionAdmins: InstitutionAdminData[],
}

const defaultInstitutionAdmin = {
  rut: '',
  name: '',
  lastname: '',
  email: '',
  password: '',
};

const InstitutionSessionView = () => {
  const {
    institutionId,
    institutionUrlExtension,
  } = useParams<{ institutionId?: string, institutionUrlExtension?: string }>();
  const { state } = useLocation<LocationState>();
  const history = useHistory();

  const [showLoading, setShowLoading] = useState(true);
  const [allSessions, setAllSessions] = useState<{
    totalItems: number,
    items: InstitutionSession[]
  }>({
    totalItems: 0,
    items: [],
  });
  const [tableParameters, setTableParameters] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const [upsertSessionModal, setUpsertSessionModal] = useState({ id: '', open: false });
  const [updateTable, setUpdateTable] = useState(false);
  const [modalValues, setModalValues] = useState<{
    institutionId: string,
    institutionName: string,
    name: string,
    startDate: Date,
    endDate: Date,
    resultsDate: Date,
    active: boolean,
    institutionSessionTests: { id: number, durationMinutes: number }[]
  }>({
    institutionId: '',
    institutionName: '',
    name: '',
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
    resultsDate: new Date(Date.now()),
    active: true,
    institutionSessionTests: [],
  });
  const [upsertConfirmationModal, setUpsertConfirmationModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState({ open: false, id: '' });
  const [institution, setInstitution] = useState<{
    id: string, name: string, institutionAdmins: InstitutionAdminData[],
  }>(
    { id: '', name: '', institutionAdmins: [] },
  );

  const [specificTest, setSpecificTest] = useState({
    isBeingModified: false,
    subjectName: '',
    testId: '',
    testName: '',
    durationMinutes: 0,
  });

  const {
    account,
    institutionAccount,
  } = useSelector((storeState: ReduxState) => storeState);

  const userAdmin = Boolean(account.user?.id);
  const institutionAdmin = Boolean(institutionAccount.institutionUser?.id);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      if (state?.institutionName && institutionId) {
        setInstitution({
          id: institutionId,
          name: state.institutionName,
          institutionAdmins: state.institutionAdmins || [],
        });
        setModalValues((prev) => ({
          ...prev,
          institutionId,
          institutionName: state.institutionName,
        }));
      } else if (institutionUrlExtension) {
        // Get Institution detail
        try {
          const resp = await getInstitutionInfoByUrlExtension({ institutionUrlExtension });
          setInstitution({
            id: resp.institution.id,
            name: resp.institution.name,
            institutionAdmins: [],
          });
          setModalValues((prev) => ({
            ...prev,
            institutionId: resp.institution.id,
            institutionName: resp.institution.name,
          }));
        } catch (error) {
          handleApiResponse(enqueueSnackbar, error as AxiosError, false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    const getAllInstitutionSessions = async () => {
      try {
        const {
          sessions, totalSessions,
        }: {
          sessions: Data[],
          totalSessions: number,
        } = await getInstitutionSessionsPerPage({
          institutionId: institution.id,
          page: tableParameters.page,
          limit: tableParameters.rowsPerPage,
          sort: 'createdAt|ASC',
        });

        const activeSwitch = sessions.filter((elem) => elem.active);
        const sessionsAndActions = sessions.map((elem) => {
          let sessionSwitchActive = false;
          let resultsButtonEnabled = false;
          const studentsButtonEnabled = true;
          let sessionActions = null;
          let sessionResultsUrl = '';
          let sessionStudentsUrl = '';

          // Preu user admin
          if (userAdmin) {
            sessionActions = twoButtonsAction(
              classes,
              elem.id as string,
              { ...elem, institutionSessionId: (elem.id as string) },
              handleUpsertModal,
              setOpenDeleteModal,
            );
            sessionResultsUrl = `/instituciones/${institution.id}/jornada/${elem.id}/resultados`;
            sessionStudentsUrl = `/instituciones/${institution.id}/jornada/${elem.id}/alumnos`;
          }

          // Institution user admin
          if (institutionAdmin) {
            sessionSwitchActive = true;
            sessionResultsUrl = `/institucion/${institutionUrlExtension}/administracion/jornada/${elem.id}/resultados`;
            sessionStudentsUrl = `/institucion/${institutionUrlExtension}/administracion/jornada/${elem.id}/alumnos`;
          }

          if (elem.institutionSessionUsers.length) {
            elem.institutionSessionUsers.forEach((u) => {
              if (u.institutionSessionUserTests.length) resultsButtonEnabled = true;
            });
          }

          return {
            ...elem,
            isSessionActive: switchActions(
              elem.active, (elem.id! as number), (activeSwitch[0]?.id as number), onSwitchChange,
              sessionSwitchActive, // Switch enable/disable based on user type
            ),
            results: oneButtonAction(
              <DashboardIcon
                className={resultsButtonEnabled ? classes.icons : classes.iconsDisabled}
              />,
              history,
              sessionResultsUrl,
              elem.name,
              resultsButtonEnabled,
            ),
            seeStudents: oneButtonAction(
              <PersonIcon className={classes.icons} />, history, sessionStudentsUrl, elem.name,
              studentsButtonEnabled,
            ),
            actions: sessionActions,
          };
        });
        setAllSessions({ items: sessionsAndActions, totalItems: totalSessions });
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      } finally {
        setShowLoading(false);
      }
    };

    if (institution.id) getAllInstitutionSessions();
  }, [updateTable, tableParameters.page, tableParameters.rowsPerPage, institution]);

  const handleUpsertModal = (
    data: InstitutionSession & {
      institutionSessionTests: InstitutionSessionTest[]
      institutionSessionId: string,
    },
  ) => {
    setModalValues((prevState) => ({
      ...prevState,
      name: data.name,
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
      resultsDate: new Date(data.resultsDate),
      active: data.active,
      institutionSessionTests: data.institutionSessionTests.map((elem) => ({
        id: elem.testId, durationMinutes: elem.test.durationMinutes,
      })),
      institutionAdmins: institution.institutionAdmins,
    }));
    setUpsertSessionModal({ id: data.institutionSessionId, open: true });
  };

  const onSwitchChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    institutionSessionId: number,
    activeSwitchId: number,
  ) => {
    const isSameSwitch = institutionSessionId === activeSwitchId;
    try {
      const response = await putActiveInstitutionSessions(
        institution.id,
        isSameSwitch ? 0 : institutionSessionId,
      );
      handleApiResponse(enqueueSnackbar, response, true);
      setUpdateTable((prev) => !prev);
    } catch (error) {
      const err = error as AxiosError;
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date | File,
    source: string,
  ) => {
    if (e instanceof Date) {
      setModalValues((prevState) => ({ ...prevState, [source]: e }));
    } else if (source === 'active') {
      setModalValues(
        (prevState) => ({
          ...prevState,
          active: (e as React.ChangeEvent<HTMLInputElement>).target.checked,
        }),
      );
    } else {
      setModalValues(
        (prevState) => ({
          ...prevState,
          [source]: (e as React.ChangeEvent<HTMLInputElement>).target.value,
        }),
      );
    }
  };

  const handleCloseModal = () => {
    setUpsertSessionModal({ id: '', open: false });
    setModalValues((prevState) => ({
      ...prevState,
      name: '',
      startDate: new Date(Date.now()),
      endDate: new Date(Date.now()),
      resultsDate: new Date(Date.now()),
      active: true,
      institutionSessionTests: [],
      institutionAdmins: [],
    }));
  };

  const handleUpsertIntitutionSession = async () => {
    if (specificTest.testId !== '') {
      // Check if specificTest is in modalValues.institutionSessionTests
      const specificTestExists = modalValues.institutionSessionTests.some(
        (test) => test.id === Number(specificTest.testId),
      );

      // If specificTest doesn't exist and has a valid testId, add it
      if (!specificTestExists && specificTest.testId) {
        modalValues.institutionSessionTests = [
          ...modalValues.institutionSessionTests,
          {
            id: Number(specificTest.testId),
            durationMinutes: specificTest.durationMinutes,
          },
        ];
      }
    }

    if (!modalValues.institutionSessionTests || modalValues.institutionSessionTests.length === 0) {
      enqueueSnackbar('Debes asignar al menos un ensayo a la sesión', { variant: 'error' });
      return;
    }

    if (modalValues.institutionSessionTests.some((test) => test.durationMinutes === 0)) {
      enqueueSnackbar('La duración de todos los ensayos es requerida', { variant: 'error' });
      return;
    }

    if (modalValues.active) {
      setUpsertConfirmationModal(true);
    } else {
      await upsertInstitutionFunction();
    }
  };

  const upsertInstitutionFunction = async () => {
    const { institutionName, ...rest } = modalValues;
    try {
      let response;

      if (upsertSessionModal.id) {
        response = await putInstitutionSession({
          ...rest,
          institutionSessionId: upsertSessionModal.id,
        });
      } else {
        response = await postInstitutionSession({ ...rest });
      }

      setSpecificTest({
        isBeingModified: false,
        subjectName: '',
        testId: '',
        testName: '',
        durationMinutes: 0,
      });

      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal();
      setUpdateTable((prev) => !prev);
    } catch (error) {
      const err = error as AxiosError;
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  const handleDeleteSession = async () => {
    try {
      const response = await deleteInstitutionSession(openDeleteModal.id);
      handleApiResponse(enqueueSnackbar, response, true);
      setOpenDeleteModal({ id: '', open: false });
      setUpdateTable((prev) => !prev);
    } catch (error) {
      const err = error as AxiosError;
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  const getHeaderButtons = () => {
    const buttons = [];
    if (userAdmin) {
      buttons.push({
        text: 'Crear jornada',
        icon: <AddIcon />,
        onClick: () => {
          setUpsertSessionModal({ id: '', open: true });
          setModalValues((prevState) => ({
            ...prevState,
            institutionAdmins: [defaultInstitutionAdmin],
          }));
        },
      });

      if (allSessions.items.some((session) => session.active === true)) {
        buttons.push({
          text: 'Login institución',
          icon: <ReplyIcon sx={{ transform: 'scaleX(-1)' }} />,
          onClick: () => {
            history.push(`/institucion/${allSessions.items[0].institution.urlExtension}/login`);
          },
        });
      }
    }

    return buttons;
  };

  const getCustomTableHeaders = () => {
    if (institutionAdmin) return INSTITUTIONS_ADMIN_SESSIONS_UI;
    return INSTITUTIONS_SESSIONS_UI;
  };

  return (
    <Page
      title="Instituciones"
    >
      <Header
        title={institution.name}
        backUrl='/instituciones'
        buttons={getHeaderButtons()}
      />
      {showLoading ? <CircularProgressComponent /> : <Box
        mt={3}
      >
        {allSessions.totalItems !== 0
          && <CustomTable
            headers={getCustomTableHeaders()}
            data={{ values: allSessions.items, count: allSessions.totalItems }}
            tableParameters={tableParameters}
            setTableParameters={setTableParameters}
          />}
        {allSessions.totalItems === 0 && <EmptyTable />}
      </Box>}
      <UpsertModal
        title='jornada'
        modalAddEdit={upsertSessionModal}
        setModalAddEdit={setUpsertSessionModal}
        handleCloseModal={handleCloseModal}
        handleAddFunction={handleUpsertIntitutionSession}
        handleEditFunction={handleUpsertIntitutionSession}
        modalValues={modalValues}
        itemsUI={INSTITUTIONS_SESSIONS_MODAL_UI}
        handleFieldChange={handleFieldChange}
      >
        <>
          <CustomSessionComponent
            testsInDB={modalValues.institutionSessionTests}
            setModalValues={setModalValues}
            specificTest={specificTest}
            setSpecificTest={setSpecificTest}
          />
        </>
      </UpsertModal>
      <DeleteModal
        deleteFunction={handleDeleteSession}
        handleCloseModal={() => setOpenDeleteModal({ id: '', open: false })}
        modalDelete={openDeleteModal}
        title='jornada'
        setModalDelete={setOpenDeleteModal}
      />
      <UpsertConfirmationModal
        upsertConfirmationModal={upsertConfirmationModal}
        setUpsertConfirmationModal={setUpsertConfirmationModal}
        upsertInstitutionFunction={upsertInstitutionFunction}
      />
    </Page>
  );
};

export default InstitutionSessionView;
