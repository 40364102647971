import React from 'react';
import {
  Box, Card, CardContent, IconButton, Modal, Theme, Typography,
} from '@mui/material';
import {
  Close as CloseIcon,
  FileUpload as FileUploadIcon,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import CustomButton from './CustomButton';
import { CustomFileField } from './CustomTextfields';

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  modalHeader: {
    paddingLeft: '10px',
  },
  modal: {
    maxHeight: '920px',
    maxWidth: '890px',
    width: '100%',
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  modalDelete: {
    maxHeight: '650px',
    maxWidth: '690px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
    overflow: 'auto',
  },
  modalTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalTitleAndIcon: {
    display: 'flex',
  },
  modalDeleteBody: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  modalDeleteButtonsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  modalFileUploadLabel: {
    width: '100%',
    display: 'flex',
  },
  icons: {
    color: theme.palette.primary.red,
    fontSize: '26px',
  },
}));

function UploadModal({
  header,
  warningMessage,
  modalDelete,
  file,
  handleFileChange,
  setModalDelete,
  handleCloseModal,
  uploadFunction,
  loading,
  children,
  uploadButtonText = 'Subir archivo',
}: {
  header: string,
  warningMessage: string,
  modalDelete: { open: boolean },
  file: File | undefined,
  handleFileChange: (file: File) => void,
  setModalDelete: React.Dispatch<React.SetStateAction<{
    open: boolean;
    id: string;
  }>>,
  handleCloseModal: (setFunction: React.Dispatch<React.SetStateAction<{
    open: boolean;
    id: string;
  }>>) => void,
  uploadFunction: () => void,
  loading: boolean,
  children?: React.ReactNode,
  uploadButtonText?: string,
}) {
  const classes = useStyles();
  return (
    <Modal
    open={modalDelete.open}
    onClose={() => handleCloseModal(setModalDelete)}
  >
    <Box className={classes.modalContainer}>
      <Card className={`${classes.modal} ${classes.modalDelete}`}>
        <CardContent className={classes.modalContent}>
          <Box className={classes.modalTitleContainer}>
            <Box className={classes.modalTitleAndIcon} >
              <FileUploadIcon />
              <Typography
                variant="h3"
                fontWeight='bold'
                className={classes.modalHeader}
              >
                {header}
              </Typography>
            </Box>
            <IconButton onClick={() => handleCloseModal(setModalDelete)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className={classes.modalDeleteBody}>
            <Typography
              variant='body1'
              fontWeight='bold'
              textAlign='center'
            >{warningMessage}</Typography>
          </Box>
          {children}
          <Box>
            <CustomFileField
              keyField='file'
              value={file}
              name={uploadButtonText}
              onChangeFunction={handleFileChange}
              labelClass={classes.modalFileUploadLabel}
            />
          </Box>
          <Box className={classes.modalDeleteButtonsContainer}>
            <CustomButton text='Cancelar' colorType='tertiary' onClick={() => handleCloseModal(setModalDelete)} key={'CancelButton'} icon={<CloseIcon />} />
            <CustomButton text='Confirmar' colorType='primary' onClick={() => uploadFunction()} key={'AcceptButton'} icon={<FileUploadIcon />} loading={loading} />
          </Box>
        </CardContent>
      </Card>
    </Box>
  </Modal>
  );
}

export default UploadModal;
