import React, { SyntheticEvent } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { CustomTextField } from '../../../../components/General/CustomTextfields';
import { InstitutionAdminData, INSTITUTIONS_ADMIN_CREATION_UI } from '../types';

import useStyles from '../../styles';
import { InstitutionInformation } from '../../types';

const TITLE = {
  create: 'Creación de Administrador',
  edit: 'Edición de Administrador',
};

export const InstitutionAdminCreation = ({
  values,
  setValues,
  format = 'create',
}: {
  values: InstitutionAdminData[],
  setValues: React.Dispatch<React.SetStateAction<InstitutionInformation>>,
  format: 'create' | 'edit',
}) => {
  const classes = useStyles();

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string,
  ) => {
    if (!(e instanceof Date)
      && !(e instanceof File)) {
      const newValue = (e.target as HTMLInputElement).value;
      const newValues = { ...values[0] };
      newValues[source] = newValue;
      setValues((prevState) => ({ ...prevState, institutionAdmins: [newValues] }));
    }
  };

  const renderOptions = () => {
    const response = INSTITUTIONS_ADMIN_CREATION_UI.map((item, i) => {
      let disabled = false;
      if (item.key === 'rut' && format === 'edit') disabled = true;
      return (
        <Grid
          item
          key={`${item.key}${i}`}
          sm={6}
        >
          <Box className={classes.institutionAdminCreationField}>
            <CustomTextField
              disabled={disabled}
              keyField={item.key}
              name={item.label}
              value={values.length === 0 ? '' : values[0][item.key]}
              onChangeFunction={(e) => handleFieldChange(e, item.key)}
            />
          </Box>
        </Grid>
      );
    });
    return response;
  };

  return (
    <Box>
      <Typography variant="h4" fontWeight='bold' mt={2} mb={1}>
        {TITLE[format]}
      </Typography>
      <Grid container>
        {renderOptions()}
      </Grid>
    </Box>
  );
};
