import { Dispatch } from 'redux';
import AuthService from '../services/authService';
import { User } from '../types';
import AppActions from './appActions';
import { INSTITUTION_LOGOUT } from './institutionAccountActions';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const UPDATE_PROFILE = '@account/update-profile';

export const loginSuccess = (userData: User) => async (dispatch: Dispatch<AppActions>) => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload: {
      userData,
    },
  });
};

export const setUserData = (userData: User) => (dispatch: Dispatch<AppActions>) => {
  dispatch({
    type: SILENT_LOGIN,
    payload: {
      userData,
    },
  });
};

export const logout = () => async (dispatch: Dispatch<AppActions>) => {
  AuthService.logoutSession();

  dispatch({
    type: LOGOUT,
  });

  dispatch({
    type: INSTITUTION_LOGOUT,
  });
};
