import { Box, Typography } from '@mui/material';
import React from 'react';
import useStyles from '../../styles';

const DisplayInfoComponent = ({ title, subTitle }: { title: string, subTitle: string }) => {
  const classes = useStyles();

  return (
    <Box display='flex' flexDirection='column' className={classes.displayInfoBox}>
    <Box>
      <Typography
        variant='h4'
        color='tertiary'
      >
        {title}
      </Typography>

    </Box>
    <Box>
      <Typography
        variant='body1'
        color='tertiary'
      >
        {subTitle}
      </Typography>
    </Box>
  </Box>
  );
};

export default DisplayInfoComponent;
