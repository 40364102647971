import { Box, Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Send as SendIcon,
} from '@mui/icons-material';
import DisplayInfoComponent from './DisplayInfoComponent';
import { parseSecondsToTime } from '../../../../utils/helpers';
import CustomButton from '../../../../components/General/CustomButton';
import LocalTestInformation from '../../../../services/institutionUserTestService';
import useStyles from '../../styles';

const HeaderInformation = ({ userAnswers, handleDeliverTest, durationMinutes }: {
  userAnswers: { [key: string]: { answer: string | undefined, markedForRevision: boolean } },
  handleDeliverTest: () => void,
  durationMinutes: number,
}) => {
  const classes = useStyles();
  const numOfQuestions = Object.keys(userAnswers).length;
  const [timer, setTimer] = useState(0);

  const startTimer = useCallback(({ startTime }: Record<string, number>) => {
    const timerId = setInterval(() => {
      const startTimeInSeconds = startTime / 1000;
      const durationMinutesInSeconds = durationMinutes * 60;
      const timeRemaining = Math
        .round(durationMinutesInSeconds - (Date.now() / 1000 - startTimeInSeconds));

      if (timeRemaining <= 0) {
        handleDeliverTest();
      } else {
        setTimer(timeRemaining);
      }
    }, 1000);
    return timerId;
  }, []);

  useEffect(() => {
    const userTimer = LocalTestInformation.getTestTimerInformation();

    if (!userTimer) {
      LocalTestInformation.setTestTimerInformation();
    }

    const timerId = startTimer({
      startTime: userTimer || Date.now(),
    });

    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <Grid item mt={2} display='flex' justifyContent='space-between'>
      <Box display='flex' justifyContent='space-between' className={classes.testInformation}>
        <DisplayInfoComponent title={'Tiempo restante:'} subTitle={parseSecondsToTime(timer)} />
        <DisplayInfoComponent title={'Respondidas:'} subTitle={`${Object.values(userAnswers).reduce((acc, val) => acc + ((val?.answer) ? 1 : 0), 0)}/${numOfQuestions}`} />
        <DisplayInfoComponent title={'Por revisar:'} subTitle={`${Object.values(userAnswers).reduce((acc, val) => acc + (val?.markedForRevision ? 1 : 0), 0)}/${numOfQuestions}`} />
      </Box>
      <CustomButton text={'Entregar guía'} colorType='tertiary' size="mobile" onClick={handleDeliverTest} icon={<SendIcon />} />
    </Grid>
  );
};

export default HeaderInformation;
