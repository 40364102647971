import { Box } from '@mui/material';
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { StackBarChartProps } from '../types';

export const StackBarChart = ({ testsBySubjectAndLocation } : StackBarChartProps) => {
  const categories: { [subject: string]: boolean } = {};
  const seriesInPersonData: number[] = [];
  const seriesOnlineData: number[] = [];
  if (Object.keys(testsBySubjectAndLocation).length) {
    Object.keys(testsBySubjectAndLocation).forEach((key) => {
      const data = testsBySubjectAndLocation[key];
      if (data) {
        categories[key] = true;
        seriesInPersonData.push(data.inPerson);
        seriesOnlineData.push(data.online);
      }
    });
  }

  const stackedBarChartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Ensayos rendidos',
      align: 'left',
    },
    xAxis: {
      categories: Object.keys(categories),
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Número ensayos',
      },
      stackLabels: {
        enabled: true,
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [
      {
        name: 'Presencial',
        data: seriesInPersonData,
      },
      {
        name: 'Online',
        data: seriesOnlineData,
      },
    ],
  };

  return (
    <Box style={{ width: '33%' }}>
    <HighchartsReact highcharts={Highcharts} options={stackedBarChartOptions} />
  </Box>
  );
};
