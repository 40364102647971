import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableRow,
  IconButton,
  TextField,
  MenuItem,
  Autocomplete,
} from '@mui/material';
import React, {
  SyntheticEvent, useEffect, useState,
} from 'react';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import handleApiResponse from '../../../../utils/handleApiResponse';
import { getTests } from '../../../../requests/api/institutions';

type SubjectByTest = {
  [key: string]: { subjectName: string, testName: string, durationMinutes: number, },
};

const CustomSessionComponent = ({
  testsInDB,
  setModalValues,
  specificTest,
  setSpecificTest,
}: {
  testsInDB: { id: number, durationMinutes: number }[],
  specificTest: {
    isBeingModified: boolean,
    subjectName: string,
    testId: string,
    testName: string,
    durationMinutes: number,
  },
  setSpecificTest: React.Dispatch<React.SetStateAction<{
    isBeingModified: boolean,
    subjectName: string,
    testId: string,
    testName: string,
    durationMinutes: number,
  }>>,
  setModalValues: React.Dispatch<React.SetStateAction<{
    institutionId: string,
    institutionName: string,
    name: string,
    startDate: Date,
    endDate: Date,
    resultsDate: Date,
    active: boolean,
    institutionSessionTests: { id: number, durationMinutes: number }[],
  }>>,
}) => {
  const [testsInSesssion, setTestsInSesssion] = useState<{
    testId: string | number,
    subjectName: string
    durationMinutes: number,
  }[]>([]);
  const [allAvailableTests, setAllAvailableTests] = useState<{
    [key: string]: { id: number, name: string }[],
  }>({});
  const [subjectsByTest, setSubjectsByTest] = useState<SubjectByTest>({});
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getInitialInformation = async () => {
      let localSubjectByTest: SubjectByTest;
      try {
        const { testsBySubject, subjectByTest } = await getTests();
        localSubjectByTest = subjectByTest;
        setAllAvailableTests(testsBySubject);
        setSubjectsByTest(subjectByTest);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }

      try {
        const auxTestsInDB: {
          testId: string | number, subjectName: string, durationMinutes: number,
        }[] = [];
        testsInDB.forEach((elem) => {
          if (localSubjectByTest[elem.id]) {
            auxTestsInDB.push({
              testId: elem.id,
              subjectName: localSubjectByTest[elem.id].subjectName,
              durationMinutes: elem.durationMinutes,
            });
          }
        });
        setTestsInSesssion(auxTestsInDB);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };
    getInitialInformation();
  }, []);

  const handleValuesChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event>,
    source: string,
    value?: any,
  ) => {
    // Parse the new value for duration if applicable
    const newDurationValue = source === 'durationMinutes'
      ? parseInt((e as React.ChangeEvent<HTMLInputElement>).target.value, 10) || 0
      : null;

    // Helper function to update specific test details
    const updateSpecificTest = (updates: Partial<typeof specificTest>) => {
      setSpecificTest((prevState) => ({
        ...prevState,
        ...updates,
      }));
    };

    // Helper function to update tests in the session
    const updateTestsInSession = (
      testId: string | number,
      durationMinutes: number,
    ) => {
      const updatedTestsInSession = testsInSesssion.map(
        (test) => (test.testId === testId ? { ...test, durationMinutes } : test),
      );

      setTestsInSesssion(updatedTestsInSession);

      // Update modal values based on the new test session
      const arrayOfTestIds = updatedTestsInSession.map((elem) => ({
        id: Number(elem.testId),
        durationMinutes: elem.durationMinutes,
      }));

      setModalValues((prevState) => ({
        ...prevState,
        institutionSessionTests: arrayOfTestIds,
      }));
    };

    // Switch statement to handle each source type
    switch (source) {
      case 'durationMinutes':
        if (newDurationValue !== null && value) {
          updateTestsInSession(value, newDurationValue);

          if (specificTest.testId === value) {
            updateSpecificTest({ durationMinutes: newDurationValue });
          }
        }
        break;

      case 'subjectName':
        updateSpecificTest({
          subjectName: (e as React.ChangeEvent<HTMLInputElement>).target.value,
          testId: '',
          testName: '',
          durationMinutes: 0,
        });
        break;

      case 'testId':
        if (value) {
          updateSpecificTest({ testId: value.value });

          const newTestArray = [
            ...testsInSesssion.map((elem) => ({
              id: elem.testId,
              durationMinutes: elem.durationMinutes,
            })),
            { id: value.value, durationMinutes: 0 },
          ];

          setModalValues((prevState) => ({
            ...prevState,
            institutionSessionTests: newTestArray,
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleAddingNewTests = () => {
    if (Object.keys(allAvailableTests).length === 0) {
      enqueueSnackbar('No hay ensayos con enunciado y solucionario para ser elegidos.', { variant: 'error' });
      return;
    }

    if (specificTest.isBeingModified && specificTest.testId) {
      // Add the specific test to testsInSesssion
      const newTest = {
        subjectName: specificTest.subjectName,
        testId: specificTest.testId,
        durationMinutes: specificTest.durationMinutes,
      };

      // Update testsInSesssion state and commit changes
      setTestsInSesssion((prevState) => {
        const updatedTestsInSession = [...prevState, newTest];

        // Update the modal values based on the updated testsInSesssion
        setModalValues((prev) => ({
          ...prev,
          institutionSessionTests: updatedTestsInSession.map((elem) => ({
            id: Number(elem.testId),
            durationMinutes: elem.durationMinutes,
          })),
        }));

        return updatedTestsInSession;
      });

      setSpecificTest({
        subjectName: '',
        testId: '',
        testName: '',
        isBeingModified: true,
        durationMinutes: 0,
      });
    } else {
      setSpecificTest((prevState) => ({ ...prevState, isBeingModified: true }));
    }
  };

  const removeCurrentTest = (testId: string) => {
    const testsToRemain = testsInSesssion.filter((elem) => `${elem.testId}` !== `${testId}`);
    setTestsInSesssion(testsToRemain);
    setModalValues((prevState) => ({
      ...prevState,
      institutionSessionTests: testsToRemain.map(
        (elem) => ({ id: elem.testId as number, durationMinutes: elem.durationMinutes }),
      ),
    }));
  };

  const removeNewTest = () => {
    setSpecificTest({
      isBeingModified: false,
      subjectName: '',
      testId: '',
      testName: '',
      durationMinutes: 0,
    });
  };

  const renderMenuItems = (relTest: {
    testId: string | number,
    subjectName: string
  }[]) => {
    if (relTest.length > 0) {
      return [{
        label: subjectsByTest[relTest[0].testId].testName,
        value: relTest[0].testId,
      }];
    }

    if (specificTest.subjectName) {
      return allAvailableTests[specificTest.subjectName].map((option) => ({
        label: option.name,
        value: option.id,
      }));
    }
    return [];
  };

  const testsUI = ({
    subjectName, testId, durationMinutes,
  }: {
    testId: string | number,
    subjectName: string,
    durationMinutes?: number,
  }) => {
    let deleteFunction;
    const relTest = testsInSesssion.filter((elem) => elem.testId === testId);
    if (relTest.length > 0) {
      deleteFunction = () => removeCurrentTest(testId as string);
    } else {
      deleteFunction = () => removeNewTest();
    }

    const menuItems = renderMenuItems(relTest);

    return (
      <TableRow key={`row-item-content-${testId}`}>
        <TableCell>
          <TextField
            key={`subject-${testId}`}
            select
            fullWidth
            name="Asignatura"
            placeholder='Selecciona una asignatura'
            disabled={relTest.length > 0}
            value={subjectName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleValuesChange(e, 'subjectName')}
          >
            {Object.keys(allAvailableTests).map((option) => (
              <MenuItem
                key={option}
                value={option}
              >
                {option}
              </MenuItem>
            ))}
          </TextField>
        </TableCell>
        <TableCell>
          <Autocomplete
            options={menuItems}
            fullWidth
            value={menuItems.find((option) => option.value === testId) || null}
            getOptionLabel={(option) => option.label}
            onChange={(e: SyntheticEvent<Element, Event>, value) => handleValuesChange(e, 'testId', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Ensayo"
                variant="outlined"
                color="secondary"
                placeholder="Ingresa un nombre"
              />
            )}
            sx={testId === 'Otro' ? { marginBottom: '5px !important' } : {}}
            disabled={relTest.length > 0}
          />
        </TableCell>
        <TableCell sx={{ width: '200px' }}>
          <TextField
            fullWidth
            label="Duración"
            name="durationMinutes"
            onChange={(e) => handleValuesChange(e, 'durationMinutes', testId)}
            value={`${durationMinutes}`}
          />
        </TableCell>
        <TableCell>
          <IconButton onClick={deleteFunction}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box>
      <Typography variant='h4' fontWeight='bold' mt={2} mb={3}>Ensayos</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Asignatura</TableCell>
            <TableCell>Nombre Ensayo</TableCell>
            <TableCell>Duración ensayo (minutos)</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {testsInSesssion.length > 0
            && testsInSesssion
              .map((elem) => testsUI({
                subjectName: elem.subjectName,
                testId: elem.testId,
                durationMinutes: elem.durationMinutes,
              }))
          }
          {specificTest.isBeingModified && testsUI({
            subjectName: specificTest.subjectName,
            testId: specificTest.testId,
            durationMinutes: specificTest.durationMinutes,
          })}
          <TableRow>
            <TableCell colSpan={4}>
              <IconButton onClick={handleAddingNewTests}>
                <AddIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default CustomSessionComponent;
