import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  SvgIcon,
  // IconButton,
  Toolbar,
  // SvgIcon,
} from '@mui/material';
// import { Menu as MenuIcon } from 'react-feather';
import { Menu } from '@mui/icons-material';
import Logo from '../../Layout/Logo';
import Account from './Account';

import useStyles from './styles';
import { TopBarProps } from '../../../types';

function TopBar({
  className,
  onMobileNavOpen,
  ...rest
}: TopBarProps) {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
            size="large"
            >
            <SvgIcon fontSize="small">
              <Menu />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden lgDown>
          <Logo className={classes.logo} />
        </Hidden>
        {/* <Box
          ml={2}
          flexGrow={1}
        /> */}
        {/* <Settings /> */}
        <Box className={classes.accountContainer}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
