import { InstitutionAdminData } from './InstitutionsListView/types';

type InstitutionInformation = {
  id?: string,
  name: string,
  urlExtension: string,
  logoFile: undefined | File,
  institutionUsers?: InstitutionAdminData[],
  institutionAdmins: InstitutionAdminData[]
};

const INSTITUTIONS_UI = [
  {
    label: 'Nombre Institución', key: 'name', valueType: 'string',
  },
  {
    label: 'URL', key: 'urlExtension', valueType: 'string',
  },
  {
    label: 'Acción', key: 'editAction', valueType: 'other',
  },
  {
    label: '', key: 'detailAction', valueType: 'other',
  },
];

export { InstitutionInformation, INSTITUTIONS_UI };
